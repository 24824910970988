// src/components/dashboard/tabs/SandboxCard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress,
    Tooltip,
    Avatar
} from '@mui/material';
import {
    MoreVertical,
    Share2,
    Trash2,
    ExternalLink,
    Calendar,
    ArrowUpRight,
    UserCheck,
    Database
} from 'lucide-react';
import { startCamundaProcess } from '../../../services/apiService';

const SandboxCard = ({
                         sandbox,
                         isOwner,
                         onDelete,
                         onShare,
                         token,
                         setError
                     }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // States for menus and dialogs
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Menu handlers
    const handleOpenMenu = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };

    // Navigate to sandbox
    const handleNavigateToSandbox = () => {
        navigate(`/sandbox/${sandbox.id}`);
        handleCloseMenu();
    };

    // Share sandbox
    const handleShare = () => {
        handleCloseMenu();
        if (onShare) {
            onShare(sandbox);
        }
    };

    // Delete sandbox
    const handleOpenDeleteConfirm = () => {
        handleCloseMenu();
        setDeleteConfirmOpen(true);
    };

    const handleDeleteSandbox = async () => {
        try {
            setIsDeleting(true);
            await startCamundaProcess(
                token,
                'delete',
                sandbox.owner?.id, // Use optional chaining in case owner isn't included
                'delete',
                sandbox.id
            );
            setIsDeleting(false);
            setDeleteConfirmOpen(false);

            // Notify parent component about deletion
            if (onDelete) {
                onDelete(sandbox.id);
            }
        } catch (err) {
            setIsDeleting(false);
            setError && setError(t('dashboard.sandboxes.error.deleting'));
            console.error('Error deleting sandbox:', err);
        }
    };

    return (
        <Card className="h-full flex flex-col border border-gray-200 hover:shadow-md transition-shadow rounded-xl overflow-hidden">
            {/* Card header */}
            <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-4 border-b border-gray-200">
                <div className="flex justify-between items-start">
                    <div>
                        <Typography
                            variant="h6"
                            className="text-blue-700 font-semibold cursor-pointer hover:text-blue-900"
                            onClick={handleNavigateToSandbox}
                        >
                            {sandbox.name}
                        </Typography>
                    </div>
                    <IconButton
                        size="small"
                        onClick={handleOpenMenu}
                        aria-label={t('dashboard.sandboxes.actions')}
                    >
                        <MoreVertical className="w-5 h-5" />
                    </IconButton>
                </div>
            </div>

            {/* Card content */}
            <CardContent className="flex-grow flex flex-col p-4">
                {/* Sandbox URL */}
                {sandbox.host && (
                    <div className="mb-3">
                        <Tooltip title={sandbox.host}>
                            <Typography
                                variant="body2"
                                component="div"
                                className="flex items-center text-gray-600 text-sm truncate"
                            >
                                <Database className="w-4 h-4 mr-2 flex-shrink-0" />
                                <span className="truncate">{sandbox.host}</span>
                            </Typography>
                        </Tooltip>
                    </div>
                )}

                {/* Sandbox metadata */}
                <div className="mt-auto text-sm text-gray-600">
                    {sandbox.created_at && (
                        <div className="flex items-center mt-2">
                            <Calendar className="w-4 h-4 mr-2" />
                            <span>{t('dashboard.sandboxes.created')}: {new Date(sandbox.created_at).toLocaleDateString()}</span>
                        </div>
                    )}

                    {/* Show owner or coworkers info if available */}
                    {sandbox.owner && (
                        <div className="flex items-center mt-2">
                            <Avatar
                                sx={{ width: 20, height: 20, fontSize: '0.75rem', marginRight: '8px' }}
                                alt={sandbox.owner.username}
                            >
                                {sandbox.owner && sandbox.owner.username
                                    ? sandbox.owner.username.charAt(0).toUpperCase()
                                    : '?'}
                            </Avatar>
                            <span>{t('dashboard.sandboxes.owner')}: {sandbox.owner.username}</span>
                        </div>
                    )}

                    {sandbox.coworkers && sandbox.coworkers.length > 0 && (
                        <div className="flex items-center mt-2">
                            <UserCheck className="w-4 h-4 mr-2" />
                            <span>
                                {t('dashboard.sandboxes.shared_with', { count: sandbox.coworkers.length })}
                            </span>
                        </div>
                    )}
                </div>
            </CardContent>

            {/* Card actions */}
            <CardActions className="border-t border-gray-200 p-2 bg-gray-50">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<ArrowUpRight className="w-4 h-4" />}
                    onClick={handleNavigateToSandbox}
                    fullWidth
                >
                    {t('dashboard.sandboxes.open')}
                </Button>
            </CardActions>

            {/* Context Menu */}
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={handleNavigateToSandbox}>
                    <ExternalLink className="w-4 h-4 mr-2" />
                    {t('dashboard.sandboxes.open')}
                </MenuItem>

                {isOwner && (
                    <>
                        <MenuItem onClick={handleShare}>
                            <Share2 className="w-4 h-4 mr-2" />
                            {t('dashboard.sandboxes.share')}
                        </MenuItem>

                        <MenuItem onClick={handleOpenDeleteConfirm} className="text-red-600">
                            <Trash2 className="w-4 h-4 mr-2" />
                            {t('dashboard.sandboxes.delete')}
                        </MenuItem>
                    </>
                )}
            </Menu>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteConfirmOpen}
                onClose={() => !isDeleting && setDeleteConfirmOpen(false)}
            >
                <DialogTitle className="text-red-600">
                    {t('dashboard.sandboxes.delete_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('dashboard.sandboxes.delete_confirm', { name: sandbox.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteConfirmOpen(false)}
                        disabled={isDeleting}
                    >
                        {t('dashboard.sandboxes.cancel')}
                    </Button>
                    <Button
                        onClick={handleDeleteSandbox}
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        startIcon={isDeleting ? <CircularProgress size={20} /> : <Trash2 size={16} />}
                    >
                        {isDeleting
                            ? t('dashboard.sandboxes.deleting')
                            : t('dashboard.sandboxes.delete')
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default SandboxCard;