import React, { useState } from 'react';
import {
    Rocket,
    Book,
    Users,
    Bot,
    Code,
    Plus,
    Trash2,
    Edit3,
    CheckCircle2,
    Clock,
    Star,
    Trophy
} from 'lucide-react';

// Имитация данных
const mockData = {
    userInfo: {
        id: 1,
        username: 'JohnDoe',
        profile: {
            name: 'John',
            last_name: 'Doe',
            bio: 'Frontend developer & educator',
            level: 4,
            points: 720,
            avatar: null
        }
    },
    sandboxes: {
        own: [
            { id: 1, name: 'E-commerce Platform', status: 'active' },
            { id: 2, name: 'Task Manager', status: 'active' }
        ],
        available: [
            { id: 3, name: 'Blog System', status: 'active' },
            { id: 4, name: 'Social Network', status: 'active' }
        ],
        templates: [
            { id: 1, name: 'Basic CRUD App', description: 'Simple CRUD application template' },
            { id: 2, name: 'Authentication System', description: 'Ready-to-use auth system' }
        ],
        availableTemplates: [
            { id: 3, name: 'RESTful API', description: 'API with documentation' },
            { id: 4, name: 'Progressive Web App', description: 'PWA starter template' }
        ]
    },
    courses: {
        enrolled: [
            { id: 1, title: 'Web Development Fundamentals', progress: 85, status: 'in_progress' },
            { id: 2, title: 'Advanced React Patterns', progress: 60, status: 'in_progress' }
        ],
        created: [
            { id: 3, title: 'Introduction to Node.js', students: 24 },
            { id: 4, title: 'Database Design', students: 17 }
        ],
        assignments: {
            assigned: [
                { id: 1, title: 'Create a REST API', dueDate: '2025-04-15', courseTitle: 'Web Development Fundamentals' },
                { id: 2, title: 'Implement Context API', dueDate: '2025-04-10', courseTitle: 'Advanced React Patterns' }
            ],
            created: [
                { id: 3, title: 'Build a simple server', submissions: 18, courseTitle: 'Introduction to Node.js' },
                { id: 4, title: 'Design a database schema', submissions: 12, courseTitle: 'Database Design' }
            ]
        }
    },
    aiTutors: {
        own: [
            { id: 1, name: 'JS Helper', description: 'JavaScript coding tutor', type: 'code' },
            { id: 2, name: 'SQL Mentor', description: 'Database query assistant', type: 'code' }
        ],
        available: [
            { id: 3, name: 'Math Tutor', description: 'Mathematics learning assistant', type: 'education' },
            { id: 4, name: 'React Coach', description: 'React development assistant', type: 'code' }
        ]
    },
    groups: {
        member: [
            { id: 1, name: 'Frontend Developers', members: 15, role: 'member' },
            { id: 2, name: 'Database Experts', members: 8, role: 'moderator' }
        ],
        owner: [
            { id: 3, name: 'Project Alpha Team', members: 5, role: 'owner' },
            { id: 4, name: 'JavaScript Study Group', members: 12, role: 'owner' }
        ]
    }
};

// Основной компонент дашборда
const DashboardTabsProto = () => {
    const [activeTab, setActiveTab] = useState('dashboard');


    return (
        <div className="min-h-screen w-screen bg-gray-50">
            {/* Основной контент */}
            <div className="pt-16 pb-8">
                {/* Вкладки */}
                <div className="max-w-7xl mx-auto px-4">
                    <div className="border-b border-gray-200 mb-6">
                        <nav className="flex -mb-px space-x-8">
                            <button
                                onClick={() => setActiveTab('dashboard')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'dashboard'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                Дашборд
                            </button>
                            <button
                                onClick={() => setActiveTab('sandboxes')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'sandboxes'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                Песочницы
                            </button>
                            <button
                                onClick={() => setActiveTab('courses')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'courses'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                Курсы и задания
                            </button>
                            <button
                                onClick={() => setActiveTab('ai-tutors')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'ai-tutors'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                AI-Tutor
                            </button>
                            <button
                                onClick={() => setActiveTab('groups')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'groups'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                Группы
                            </button>
                            <button
                                onClick={() => setActiveTab('api')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'api'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                Внешнее API
                            </button>
                        </nav>
                    </div>

                    {/* Содержимое вкладок */}
                    <div className="mb-8">
                        {activeTab === 'dashboard' && <DashboardTab />}
                        {activeTab === 'sandboxes' && <SandboxesTab />}
                        {activeTab === 'courses' && <CoursesTab />}
                        {activeTab === 'ai-tutors' && <AITutorsTab />}
                        {activeTab === 'groups' && <GroupsTab />}
                        {activeTab === 'api' && <APITab />}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Компоненты для каждой вкладки
const DashboardTab = () => {
    const userData = mockData.userInfo;
    return (
        <div>
            <div className="">
                {/* Профиль */}
                <div className="max-w-7xl mx-auto px-4 mb-8">
                    <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                        <div className="flex items-start justify-between">
                            <div className="flex gap-6">
                                <div className="bg-orange-500 rounded-full h-12 w-12 flex items-center justify-center text-white text-xl">
                                    {userData.profile.avatar || userData.username.charAt(0).toUpperCase()}
                                </div>
                                <div>
                                    <div className="flex items-center gap-3">
                                        <h1 className="text-2xl font-bold">
                                            {userData.profile.name ? `${userData.profile.name} ${userData.profile.last_name}` : userData.username}
                                        </h1>
                                        <button className="p-1 rounded hover:bg-gray-100">
                                            <Edit3 className="w-4 h-4" />
                                        </button>
                                    </div>
                                    <p className="text-gray-600 mt-1">{userData.profile.bio || 'Добавьте краткую информацию о себе'}</p>
                                    <div className="flex gap-4 mt-4">
                                        <div className="flex items-center gap-2">
                                            <Trophy className="w-5 h-5 text-yellow-500" />
                                            <span>Уровень {userData.profile.level || 1}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Star className="w-5 h-5 text-purple-500" />
                                            <span>{userData.profile.points || 0} баллов</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Мои песочницы */}
                <section className="bg-white rounded-xl shadow-sm p-6">
                    <div className="flex items-center gap-2 mb-6">
                        <Rocket className="w-6 h-6 text-blue-500" />
                        <h2 className="text-xl font-semibold">Мои песочницы</h2>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        {mockData.sandboxes.own.map(sandbox => (
                            <div key={sandbox.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                                <div className="flex justify-between items-start">
                                    <a href="https://example.com" className="text-lg font-medium text-blue-600 hover:text-blue-800">
                                        {sandbox.name}
                                    </a>
                                    <button className="text-red-500 hover:text-red-700">
                                        <Trash2 className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        ))}
                        <button className="bg-gray-50 rounded-lg border-2 border-dashed border-gray-300 p-4 hover:border-blue-500 hover:bg-gray-100 transition-colors flex flex-col items-center justify-center gap-2">
                            <Plus className="w-8 h-8 text-gray-400" />
                            <span className="text-gray-600">Создать песочницу</span>
                        </button>
                    </div>
                </section>

                {/* Мои курсы */}
                <section className="bg-white rounded-xl shadow-sm p-6">
                    <div className="flex items-center gap-2 mb-6">
                        <Book className="w-6 h-6 text-green-500" />
                        <h2 className="text-xl font-semibold">Мои курсы</h2>
                    </div>
                    <div className="space-y-4">
                        {mockData.courses.enrolled.map(course => (
                            <div key={course.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                                <div className="flex justify-between items-start">
                                    <div>
                                        <a href="https://example.com" className="text-lg font-medium text-blue-600 hover:text-blue-800">
                                            {course.title}
                                        </a>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        {course.progress >= 100 ? (
                                            <CheckCircle2 className="w-5 h-5 text-green-500" />
                                        ) : (
                                            <Clock className="w-5 h-5 text-blue-500" />
                                        )}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="w-full bg-gray-200 rounded-full h-2">
                                        <div
                                            className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                                            style={{ width: `${course.progress || 0}%` }}
                                        />
                                    </div>
                                    <span className="text-sm text-gray-600 mt-1">
                  Прогресс: {Math.round(course.progress || 0)}%
                </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

const SandboxesTab = () => {
    return (
        <div className="space-y-8">
            {/* Мои песочницы */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <Rocket className="w-6 h-6 text-blue-500" />
                        <h2 className="text-xl font-semibold">Мои песочницы</h2>
                    </div>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center gap-2">
                        <Plus className="w-4 h-4" />
                        <span>Создать песочницу</span>
                    </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.sandboxes.own.map(sandbox => (
                        <div key={sandbox.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <a href="https://example.com" className="text-lg font-medium text-blue-600 hover:text-blue-800">
                                    {sandbox.name}
                                </a>
                                <div className="flex gap-2">
                                    <button className="text-red-500 hover:text-red-700">
                                        <Trash2 className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Доступные песочницы */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center gap-2 mb-6">
                    <h2 className="text-xl font-semibold">Доступные песочницы</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.sandboxes.available.map(sandbox => (
                        <div key={sandbox.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <a href="https://example.com" className="text-lg font-medium text-blue-600 hover:text-blue-800">
                                    {sandbox.name}
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Мои шаблоны */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <h2 className="text-xl font-semibold">Мои шаблоны</h2>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.sandboxes.templates.map(template => (
                        <div key={template.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <div>
                                    <a href="https://example.com" className="text-lg font-medium text-blue-600 hover:text-blue-800">
                                        {template.name}
                                    </a>
                                    <p className="text-sm text-gray-600 mt-1">{template.description}</p>
                                </div>
                                <div className="flex gap-2">
                                    <button className="text-red-500 hover:text-red-700">
                                        <Trash2 className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Доступные шаблоны */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center gap-2 mb-6">
                    <h2 className="text-xl font-semibold">Доступные шаблоны</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.sandboxes.availableTemplates.map(template => (
                        <div key={template.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <div>
                                    <a href="https://example.com" className="text-lg font-medium text-blue-600 hover:text-blue-800">
                                        {template.name}
                                    </a>
                                    <p className="text-sm text-gray-600 mt-1">{template.description}</p>
                                </div>
                                <button className="bg-green-500 hover:bg-green-600 text-white py-1 px-3 rounded text-sm">
                                    Использовать
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

const CoursesTab = () => {
    return (
        <div className="space-y-8">
            {/* Курсы, которые я прохожу */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <Book className="w-6 h-6 text-green-500" />
                        <h2 className="text-xl font-semibold">Курсы, которые я прохожу</h2>
                    </div>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center gap-2">
                        <Plus className="w-4 h-4" />
                        <span>Посмотреть каталог курсов</span>
                    </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.courses.enrolled.map(course => (
                        <div key={course.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <div>
                                    <a href="https://example.com" className="text-lg font-medium text-blue-600 hover:text-blue-800">
                                        {course.title}
                                    </a>
                                </div>
                                <div className="flex items-center gap-2">
                                    {course.progress >= 100 ? (
                                        <CheckCircle2 className="w-5 h-5 text-green-500" />
                                    ) : (
                                        <Clock className="w-5 h-5 text-blue-500" />
                                    )}
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                                        style={{ width: `${course.progress || 0}%` }}
                                    />
                                </div>
                                <span className="text-sm text-gray-600 mt-1">
                  Прогресс: {Math.round(course.progress || 0)}%
                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Курсы, которые я создал */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <h2 className="text-xl font-semibold">Курсы, которые я создал</h2>
                    </div>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center gap-2">
                        <Plus className="w-4 h-4" />
                        <span>Создать курс</span>
                    </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.courses.created.map(course => (
                        <div key={course.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <div>
                                    <a href="https://example.com" className="text-lg font-medium text-blue-600 hover:text-blue-800">
                                        {course.title}
                                    </a>
                                    <p className="text-sm text-gray-600 mt-1">Студенты: {course.students}</p>
                                </div>
                                <div className="flex gap-2">
                                    <button className="text-gray-500 hover:text-gray-700">
                                        <Edit3 className="w-4 h-4" />
                                    </button>
                                    <button className="text-red-500 hover:text-red-700">
                                        <Trash2 className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Задания, которые мне назначены */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center gap-2 mb-6">
                    <h2 className="text-xl font-semibold">Задания, которые мне назначены</h2>
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Название</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Курс</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Срок сдачи</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Действия</th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {mockData.courses.assignments.assigned.map(assignment => (
                            <tr key={assignment.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm font-medium text-gray-900">{assignment.title}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-500">{assignment.courseTitle}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-500">{assignment.dueDate}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <button className="text-blue-600 hover:text-blue-900">Перейти к заданию</button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </section>

            {/* Задания, которые я создал */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <h2 className="text-xl font-semibold">Задания, которые я создал</h2>
                    </div>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center gap-2">
                        <Plus className="w-4 h-4" />
                        <span>Создать задание</span>
                    </button>
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Название</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Курс</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Сдано</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Действия</th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {mockData.courses.assignments.created.map(assignment => (
                            <tr key={assignment.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm font-medium text-gray-900">{assignment.title}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-500">{assignment.courseTitle}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-500">{assignment.submissions} решений</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    <div className="flex gap-2">
                                        <button className="text-blue-600 hover:text-blue-900">Просмотр</button>
                                        <button className="text-gray-500 hover:text-gray-700">Редактировать</button>
                                        <button className="text-red-500 hover:text-red-700">Удалить</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
};

const AITutorsTab = () => {
    return (
        <div className="space-y-8">
            {/* Мои AI-Tutors */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <Bot className="w-6 h-6 text-purple-500" />
                        <h2 className="text-xl font-semibold">Мои AI-Tutors</h2>
                    </div>
                    <button className="bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg flex items-center gap-2">
                        <Plus className="w-4 h-4" />
                        <span>Создать AI-Tutor</span>
                    </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.aiTutors.own.map(tutor => (
                        <div key={tutor.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <div>
                                    <a href="https://example.com" className="text-lg font-medium text-purple-600 hover:text-purple-800">
                                        {tutor.name}
                                    </a>
                                    <p className="text-sm text-gray-600 mt-1">{tutor.description}</p>
                                    <div className="mt-2">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                      {tutor.type}
                    </span>
                                    </div>
                                </div>
                                <div className="flex gap-2">
                                    <button className="text-gray-500 hover:text-gray-700">
                                        <Edit3 className="w-4 h-4" />
                                    </button>
                                    <button className="text-red-500 hover:text-red-700">
                                        <Trash2 className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Доступные мне AI-Tutors */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center gap-2 mb-6">
                    <h2 className="text-xl font-semibold">Доступные мне AI-Tutors</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.aiTutors.available.map(tutor => (
                        <div key={tutor.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <div>
                                    <a href="https://example.com" className="text-lg font-medium text-purple-600 hover:text-purple-800">
                                        {tutor.name}
                                    </a>
                                    <p className="text-sm text-gray-600 mt-1">{tutor.description}</p>
                                    <div className="mt-2">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                      {tutor.type}
                    </span>
                                    </div>
                                </div>
                                <button className="bg-purple-500 hover:bg-purple-600 text-white py-1 px-3 rounded text-sm">
                                    Начать разговор
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

const GroupsTab = () => {
    return (
        <div className="space-y-8">
            {/* Группы в которых я состою */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <Users className="w-6 h-6 text-green-500" />
                        <h2 className="text-xl font-semibold">Группы в которых я состою</h2>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.groups.member.map(group => (
                        <div key={group.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <div>
                                    <a href="https://example.com" className="text-lg font-medium text-green-600 hover:text-green-800">
                                        {group.name}
                                    </a>
                                    <p className="text-sm text-gray-600 mt-1">Участников: {group.members}</p>
                                    <div className="mt-2">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      {group.role}
                    </span>
                                    </div>
                                </div>
                                <button className="text-red-500 hover:text-red-700 py-1 px-3 rounded text-sm">
                                    Выйти
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Созданные мной группы */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <h2 className="text-xl font-semibold">Созданные мной группы</h2>
                    </div>
                    <button className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg flex items-center gap-2">
                        <Plus className="w-4 h-4" />
                        <span>Создать группу</span>
                    </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {mockData.groups.owner.map(group => (
                        <div key={group.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                            <div className="flex justify-between items-start">
                                <div>
                                    <a href="https://example.com" className="text-lg font-medium text-green-600 hover:text-green-800">
                                        {group.name}
                                    </a>
                                    <p className="text-sm text-gray-600 mt-1">Участников: {group.members}</p>
                                    <div className="mt-2">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      {group.role}
                    </span>
                                    </div>
                                </div>
                                <div className="flex gap-2">
                                    <button className="text-gray-500 hover:text-gray-700">
                                        <Edit3 className="w-4 h-4" />
                                    </button>
                                    <button className="text-red-500 hover:text-red-700">
                                        <Trash2 className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

const APITab = () => {
    return (
        <div className="space-y-8">
            {/* API Ключи */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <Code className="w-6 h-6 text-indigo-500" />
                        <h2 className="text-xl font-semibold">API Ключи</h2>
                    </div>
                    <button className="bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded-lg flex items-center gap-2">
                        <Plus className="w-4 h-4" />
                        <span>Создать новый ключ</span>
                    </button>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg mb-6">
                    <div className="flex justify-between items-center">
                        <div>
                            <h3 className="font-medium">Активный ключ</h3>
                            <p className="text-gray-500 text-sm mt-1">Создан: 15 марта 2025</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="bg-gray-200 py-2 px-4 rounded font-mono text-sm">
                                dkta_*****************************
                            </div>
                            <button className="text-blue-600 hover:text-blue-800 text-sm">Показать</button>
                            <button className="text-red-500 hover:text-red-700 text-sm">Отозвать</button>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg mb-8">
                    <h3 className="font-medium mb-2">Примеры использования</h3>
                    <div className="bg-gray-900 text-gray-100 p-4 rounded-lg font-mono text-sm overflow-x-auto">
            <pre>{`# Пример запроса с использованием curl
curl -X POST \\
  https://api.dvakota.com/v1/sandbox/create \\
  -H 'Authorization: Bearer dkta_your_api_key' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "name": "Новая песочница",
    "description": "Описание песочницы"
  }'`}</pre>
                    </div>
                </div>
            </section>

            {/* Документация API */}
            <section className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center gap-2 mb-6">
                    <h2 className="text-xl font-semibold">Документация API</h2>
                </div>

                <div className="space-y-6">
                    <div>
                        <h3 className="text-lg font-medium mb-2">Доступные эндпоинты</h3>
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Метод</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Эндпоинт</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Описание</th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                <tr>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="bg-green-100 text-green-800 px-2 py-1 rounded text-xs font-medium">GET</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap font-mono text-sm">/v1/sandbox/list</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">Получение списка песочниц</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded text-xs font-medium">POST</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap font-mono text-sm">/v1/sandbox/create</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">Создание новой песочницы</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded text-xs font-medium">PUT</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap font-mono text-sm">/v1/sandbox/{'{id}'}/update</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">Обновление песочницы</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="bg-red-100 text-red-800 px-2 py-1 rounded text-xs font-medium">DELETE</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap font-mono text-sm">/v1/sandbox/{'{id}'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">Удаление песочницы</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h3 className="text-lg font-medium mb-2">Лимиты API</h3>
                        <p className="text-gray-600">
                            Базовый план: 1000 запросов в день<br />
                            Расширенный план: 10000 запросов в день
                        </p>
                        <div className="mt-4">
                            <div className="w-full bg-gray-200 rounded-full h-2">
                                <div
                                    className="bg-indigo-500 h-2 rounded-full transition-all duration-300"
                                    style={{ width: '35%' }}
                                />
                            </div>
                            <span className="text-sm text-gray-600 mt-1">
                Использовано сегодня: 350/1000 запросов
              </span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DashboardTabsProto;

