// src/components/dashboard/tabs/DashboardMainTab.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Typography,
    Avatar,
    Chip, Alert,
} from '@mui/material';
import {
    Rocket,
    Book,
    Trophy,
    Trash2,
    Plus,
    Edit3,
    Award,
    Star,
    Clock,
    CheckCircle2
} from 'lucide-react';
import {
    updateUserProfile,
    startCamundaProcess,
    fetchInstanceByProcessId,
    updateInstanceArchitecture,
    createSandboxComponent,
    deployTemplate
} from '../../../services/apiService';
import CreateSandboxDialog from '../../sandbox/CreateSandboxDialog';
import GenerationLoader from '../../GenerationLoader';

const DashboardMainTab = ({
                              userInfo,
                              token,
                              profile,
                              achievements,
                              badges,
                              mySandboxes,
                              enrollments,
                              componentTypes,
                              setMySandboxes,
                              error,
                              setError
                          }) => {
    const { t } = useTranslation();

    // State для диалогов
    const [isEditing, setIsEditing] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [sandboxToDelete, setSandboxToDelete] = useState(null);

    // State для формы редактирования профиля
    const [formData, setFormData] = useState({
        name: profile?.name || '',
        last_name: profile?.last_name || '',
        bio: profile?.bio || '',
    });

    // State для процесса генерации
    const [processId, setProcessId] = useState(null);
    const [sandboxId, setSandboxId] = useState(null);
    const [generationStarted, setGenerationStarted] = useState(false);

    // Обработчики для песочниц
    const handleCreateSandbox = async (data) => {
        try {
            setError(null);

            switch (data.type) {
                case 0: // По описанию
                    const response = await startCamundaProcess(
                        token,
                        data.prompt,
                        userInfo.id,
                        'base',
                        null
                    );
                    setProcessId(response.process_id);
                    setSandboxId(response.sandbox_id);
                    break;

                case 1: // Вручную
                    // Запускаем процесс в ручном режиме
                    const manualResponse = await startCamundaProcess(
                        token,
                        'manual',
                        userInfo.id,
                        'manual',
                        null
                    );

                    // Создаем архитектуру из выбранных компонентов
                    const architecture = {
                        backend_components: data.components.map(component => ({
                            name: component.name,
                            description: component.prompt,
                            type: componentTypes.find(t => t.id === component.type)?.name || '',
                            subtype: component.subtype
                        }))
                    };

                    // Сохраняем архитектуру в instance
                    const instance = await fetchInstanceByProcessId(token, manualResponse.process_id);
                    await updateInstanceArchitecture(token, instance.id, architecture);

                    // Создаем компоненты
                    for (const component of data.components) {
                        await createSandboxComponent(token, {
                            sandbox: manualResponse.sandbox_id,
                            name: component.systemName,
                            host: `https://sandbox.dvakota.com/${manualResponse.namespace}/${component.systemName}`,
                            port: 0,
                            type_name_input: componentTypes.find(t => t.id === component.type)?.name,
                            status: 'creating',
                            configuration: {
                                name: component.systemName,
                                description: component.prompt,
                                type: componentTypes.find(t => t.id === component.type)?.name,
                                subtype: component.subtype
                            },
                            description: component.prompt
                        });
                    }

                    setProcessId(manualResponse.process_id);
                    setSandboxId(manualResponse.sandbox_id);
                    break;

                case 2: // Из шаблона
                    const templateResponse = await deployTemplate(
                        token,
                        data.templateId
                    );
                    setProcessId(templateResponse.process_id);
                    setSandboxId(templateResponse.sandbox_id);
                    break;

                case 3: // Мне повезёт
                    const luckyResponse = await startCamundaProcess(
                        token,
                        'Случайная, но очень интересная для изучения система',
                        userInfo.id,
                        'base',
                        null
                    );
                    setProcessId(luckyResponse.process_id);
                    setSandboxId(luckyResponse.sandbox_id);
                    break;

                default:
                    setError(t('dashboard.error.unknown_sandbox_type'));
                    return;
            }

            setGenerationStarted(true);
            setCreateDialogOpen(false);
        } catch (err) {
            setError(t('dashboard.error.creating_sandbox'));
            console.error('Error creating sandbox:', err);
        }
    };

    const handleDeleteClick = (sandbox) => {
        setSandboxToDelete(sandbox);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!sandboxToDelete) return;

        try {
            await startCamundaProcess(
                token,
                'delete',
                userInfo.id,
                'delete',
                sandboxToDelete.id
            );

            // Обновляем список песочниц
            setMySandboxes(prev => prev.filter(s => s.id !== sandboxToDelete.id));
            setDeleteDialogOpen(false);
            setSandboxToDelete(null);
        } catch (err) {
            setError(t('dashboard.error.deleting_sandbox'));
            console.error('Error deleting sandbox:', err);
        }
    };

    // Обработчик для редактирования профиля
    const handleSaveProfile = async () => {
        try {
            await updateUserProfile(token, profile.id, formData);
            setIsEditing(false);
            // Обновляем данные профиля в родительском компоненте будет происходить при следующем рендере
        } catch (err) {
            setError(t('dashboard.error.saving_profile'));
            console.error('Error saving profile:', err);
        }
    };

    return (
        <div className="space-y-8">
            {error && (
                <Alert severity="error" className="max-w-7xl mx-auto px-4 mb-4" onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}
            {/* Профиль */}
            <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                <div className="flex items-start justify-between">
                    <div className="flex gap-6">
                        <Avatar className="bg-[#FF6F00] mr-2">
                            {profile?.avatar
                                ? profile.avatar
                                : userInfo.username.charAt(0).toUpperCase()}
                        </Avatar>
                        <div>
                            <div className="flex items-center gap-3">
                                <h1 className="text-2xl font-bold">
                                    {profile?.name
                                        ? `${profile.name} ${profile.last_name}`
                                        : userInfo.username}
                                </h1>
                                <IconButton onClick={() => setIsEditing(true)} size="small">
                                    <Edit3 className="w-4 h-4" />
                                </IconButton>
                            </div>
                            <p className="text-gray-600 mt-1">{profile?.bio || t('dashboard.profile.add_bio')}</p>
                            <div className="flex gap-4 mt-4">
                                <div className="flex items-center gap-2">
                                    <Trophy className="w-5 h-5 text-yellow-500" />
                                    <span>{t('dashboard.profile.level')} {profile?.level || 1}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Star className="w-5 h-5 text-purple-500" />
                                    <span>{profile?.points || 0} {t('dashboard.profile.points')}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Достижения и бейджи */}
                    <div className="flex flex-col items-end gap-4">
                        {achievements?.length > 0 && (
                            <div className="flex flex-wrap gap-2">
                                <Typography variant="subtitle2" className="w-full text-gray-600 text-right">
                                    {t('dashboard.profile.achievements')}
                                </Typography>
                                {achievements.slice(0, 3).map(achievement => (
                                    <Chip
                                        key={achievement.id}
                                        label={achievement.achievement?.name || ''}
                                        icon={<Trophy className="w-4 h-4" />}
                                        size="small"
                                        className="bg-yellow-50"
                                    />
                                ))}
                                {achievements.length > 3 && (
                                    <Chip
                                        label={`+${achievements.length - 3}`}
                                        size="small"
                                        className="bg-yellow-50"
                                    />
                                )}
                            </div>
                        )}

                        {badges?.length > 0 && (
                            <div className="flex gap-2">
                                {badges.slice(0, 5).map(badge => (
                                    <Award
                                        key={badge.id}
                                        className="w-6 h-6 text-blue-500"
                                        title={badge.badge?.name}
                                    />
                                ))}
                                {badges.length > 5 && (
                                    <div className="text-sm text-gray-500">+{badges.length - 5}</div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Основные секции */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Секция песочниц */}
                <section className="bg-white rounded-xl shadow-sm p-6">
                    <div className="flex items-center gap-2 mb-6">
                        <Rocket className="w-6 h-6 text-blue-500" />
                        <h2 className="text-xl font-semibold">{t('dashboard.my_sandboxes')}</h2>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        {mySandboxes.map(sandbox => (
                            <div key={sandbox.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                                <div className="flex justify-between items-start">
                                    <Link
                                        to={`/sandbox/${sandbox.id}`}
                                        className="text-lg font-medium text-blue-600 hover:text-blue-800"
                                    >
                                        {sandbox.name}
                                    </Link>
                                    <IconButton
                                        onClick={() => handleDeleteClick(sandbox)}
                                        className="text-red-500 hover:text-red-700"
                                        size="small"
                                    >
                                        <Trash2 className="w-4 h-4" />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                        <button
                            onClick={() => setCreateDialogOpen(true)}
                            className="bg-gray-50 rounded-lg border-2 border-dashed border-gray-300 p-4 hover:border-blue-500 hover:bg-gray-100 transition-colors flex flex-col items-center justify-center gap-2"
                        >
                            <Plus className="w-8 h-8 text-gray-400" />
                            <span className="text-gray-600">{t('dashboard.to_create_sandbox')}</span>
                        </button>
                    </div>
                </section>

                {/* Секция курсов */}
                <section className="bg-white rounded-xl shadow-sm p-6">
                    <div className="flex items-center gap-2 mb-6">
                        <Book className="w-6 h-6 text-green-500" />
                        <h2 className="text-xl font-semibold">{t('dashboard.my_courses')}</h2>
                    </div>
                    <div className="space-y-4">
                        {enrollments.length > 0 ? (
                            enrollments
                                .filter(enrollment => enrollment.is_active)
                                .map(enrollment => (
                                    <div key={enrollment.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <Link
                                                    to={`/courses/${enrollment.course.id}`}
                                                    className="text-lg font-medium text-blue-600 hover:text-blue-800"
                                                >
                                                    {enrollment.course.title}
                                                </Link>
                                                {enrollment.course.description && (
                                                    <p className="text-sm text-gray-600 mt-1">
                                                        {enrollment.course.description.length > 80
                                                            ? `${enrollment.course.description.substring(0, 80)}...`
                                                            : enrollment.course.description}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="flex items-center gap-2">
                                                {enrollment.progress >= 100 ? (
                                                    <CheckCircle2 className="w-5 h-5 text-green-500" />
                                                ) : (
                                                    <Clock className="w-5 h-5 text-blue-500" />
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div
                                                    className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                                                    style={{ width: `${enrollment.progress || 0}%` }}
                                                />
                                            </div>
                                            <span className="text-sm text-gray-600 mt-1">
                        {t('dashboard.progress')} {Math.round(enrollment.progress || 0)}%
                      </span>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div className="text-center py-6 text-gray-500">
                                <p>{t('dashboard.no_active_courses')}</p>
                                <Link
                                    to="/courses"
                                    className="mt-2 inline-block text-blue-600 hover:text-blue-800"
                                >
                                    {t('dashboard.browse_courses')}
                                </Link>
                            </div>
                        )}
                    </div>
                </section>
            </div>

            {/* Диалог редактирования профиля */}
            <Dialog open={isEditing} onClose={() => setIsEditing(false)} maxWidth="sm" fullWidth>
                <DialogTitle>{t('dashboard.edit_profile')}</DialogTitle>
                <DialogContent>
                    <div className="space-y-4 mt-2">
                        <TextField
                            fullWidth
                            label={t('dashboard.form.first_name')}
                            value={formData.name}
                            onChange={(e) => setFormData({...formData, name: e.target.value})}
                        />
                        <TextField
                            fullWidth
                            label={t('dashboard.form.last_name')}
                            value={formData.last_name}
                            onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label={t('dashboard.form.bio')}
                            value={formData.bio}
                            onChange={(e) => setFormData({...formData, bio: e.target.value})}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsEditing(false)}>{t('dashboard.form.cancel')}</Button>
                    <Button variant="contained" onClick={handleSaveProfile}>{t('dashboard.form.save')}</Button>
                </DialogActions>
            </Dialog>

            {/* Диалог подтверждения удаления */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle className="text-red-600">
                    {t('dashboard.delete_sandbox.title')}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {t('dashboard.delete_sandbox.confirm_message', { name: sandboxToDelete?.name })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        className="text-gray-600"
                    >
                        {t('dashboard.delete_sandbox.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteConfirm}
                    >
                        {t('dashboard.delete_sandbox.delete')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Диалог создания песочницы */}
            <CreateSandboxDialog
                open={createDialogOpen}
                onClose={() => setCreateDialogOpen(false)}
                onSubmit={handleCreateSandbox}
                token={token}
                error={error}
                setError={setError}
                componentTypes={componentTypes}
            />

            {/* Если началась генерация, открываем лоадер */}
            {generationStarted && processId && sandboxId && (
                <GenerationLoader
                    token={token}
                    processId={processId}
                    sandboxId={sandboxId}
                />
            )}
        </div>
    );
};

export default DashboardMainTab;