import React, { useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import LessonPage from './pages/LessonPage';
import Sandbox from './pages/Sandbox';
import DashboardTabs from './components/dashboard/DashboardTabs';
import MainContent from './components/MainContent';
import Courses from './pages/Courses';
import Demo from './pages/Demo';
import DashboardTabsProto from './pages/proto';
import About from './pages/About';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import DevTools from './pages/DevTools';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import CourseDetail from './pages/CourseDetail';
import ProtectedRouteWrapper from './pages/ProtectedRouteWrapper';
import CourseConstructor from './pages/CourseConstructor';
import CourseEditor from './pages/CourseEditor';
import { AuthContext } from './App';


const AppRoutes = ({
                       handleLoginSuccess,
                       loginOpen,
                       handleCloseLogin,
                       registerOpen,
                       handleCloseRegister,
                       handleRegisterSuccess,
                   }) => {
    const navigate = useNavigate();
    const { isAuthenticated, userInfo, token, isLoading } = useContext(AuthContext);

    const onLoginSuccess = async (userToken) => {
        await handleLoginSuccess(userToken);
        navigate('/');
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div
                style={{paddingTop: '75px'}}
                className="flex w-full"
            >
                <Routes>
                    <Route
                        path="/"
                        element={isAuthenticated ? (
                            <div className="container mx-auto px-4 py-8">
                                <DashboardTabs userInfo={userInfo} token={token}/>
                            </div>
                        ) : (
                            <MainContent/>
                        )
                        }
                    />
                    <Route path="/courses" element={<Courses userInfo={userInfo} />}/>
                    <Route path="/courses/:courseId" element={<CourseDetail userInfo={userInfo} />} />
                    <Route path="/courses/:courseId/lessons/:lessonId" element={
                        <ProtectedRouteWrapper
                            token={token}
                            userInfo={userInfo}
                            component={LessonPage}
                            title="Доступ к уроку ограничен"
                            message="Для просмотра этого урока необходимо войти в систему или зарегистрироваться"
                        />
                    } />

                    <Route
                        path="/sandbox"
                        element={
                            <PrivateRoute>
                                <Sandbox userInfo={userInfo} token={token}/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/sandbox/:sandboxId"
                        element={
                            <ProtectedRouteWrapper
                                token={token}
                                userInfo={userInfo}
                                component={Sandbox}
                                title="Доступ к песочнице ограничен"
                                message="Для доступа к песочнице необходимо войти в систему или зарегистрироваться"
                            />
                        }
                    />
                    <Route
                        path="/courses/create"
                        element={
                            <CourseConstructor />
                        }
                    />
                    <Route
                        path="/courses/:courseId/edit"
                        element={
                            <CourseEditor />
                        }
                    />

                    <Route path="/demo" element={<Demo/>}/>
                    <Route path="/proto" element={<DashboardTabsProto/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms-of-service" element={<TermsOfService/>}/>
                    <Route path="/devtools" element={
                        userInfo?.is_staff? <DevTools userInfo={userInfo} token={token} /> : <div>This is a restricted area, move along and pretend you didn't see this.</div>
                        }
                    />
                    <Route path="*" element={<MainContent/>}/>
                </Routes>
            </div>

            <Dialog open={loginOpen} onClose={handleCloseLogin}>
                <DialogContent>
                    <LoginForm onLoginSuccess={onLoginSuccess} onClose={handleCloseLogin} />
                </DialogContent>
            </Dialog>

            <Dialog open={registerOpen} onClose={handleCloseRegister}>
                <DialogContent>
                    <RegisterForm onRegisterSuccess={handleRegisterSuccess} onClose={handleCloseRegister} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AppRoutes;