// src/components/dashboard/tabs/TemplateCard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Button,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
    Tooltip,
    Avatar
} from '@mui/material';
import {
    MoreVertical,
    Play,
    Share2,
    Trash2,
    Server,
    Calendar,
    CheckCircle,
    AlertTriangle,
    Loader
} from 'lucide-react';
import {
    deployTemplate,
    deleteTemplate
} from '../../../services/apiService';
import ShareTemplateDialog from './ShareTemplateDialog';

const TemplateCard = ({
                          template,
                          token,
                          setError,
                          onDeleted
                      }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Check if user is owner of the template
    const [isOwner, setIsOwner] = useState(false);

    // States for menus and dialogs
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [deployConfirmOpen, setDeployConfirmOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [isDeploying, setIsDeploying] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Determine if the current user is the owner
    useEffect(() => {
        if (template && template.created_by) {
            // This depends on how you've implemented user identification
            // You might need to compare with the current user ID
            setIsOwner(true); // For now, we assume user is owner if created_by exists
        }
    }, [template]);

    // Menu handlers
    const handleOpenMenu = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };

    // Deploy template
    const handleOpenDeployConfirm = () => {
        handleCloseMenu();
        setDeployConfirmOpen(true);
    };

    const handleDeployTemplate = async () => {
        try {
            setIsDeploying(true);
            const response = await deployTemplate(token, template.id);
            setIsDeploying(false);
            setDeployConfirmOpen(false);
            // Navigate to the new sandbox
            navigate(`/sandbox/${response.sandbox_id}`);
        } catch (err) {
            setIsDeploying(false);
            setError && setError(t('dashboard.templates.deploy_error'));
            console.error('Error deploying template:', err);
        }
    };

    // Share template
    const handleOpenShareDialog = () => {
        handleCloseMenu();
        setShareDialogOpen(true);
    };

    // Delete template
    const handleOpenDeleteConfirm = () => {
        handleCloseMenu();
        setDeleteConfirmOpen(true);
    };

    const handleDeleteTemplate = async () => {
        try {
            setIsDeleting(true);
            await deleteTemplate(token, template.id);
            setIsDeleting(false);
            setDeleteConfirmOpen(false);
            // Notify parent component about deletion
            if (onDeleted) {
                onDeleted(template.id);
            }
        } catch (err) {
            setIsDeleting(false);
            setError && setError(t('dashboard.templates.delete_error'));
            console.error('Error deleting template:', err);
        }
    };

    // Render status badge
    const renderStatusBadge = () => {
        const statusConfig = {
            'ready': {
                color: 'bg-green-100 text-green-800',
                icon: <CheckCircle className="w-4 h-4 mr-1" />
            },
            'creating': {
                color: 'bg-blue-100 text-blue-800',
                icon: <Loader className="w-4 h-4 mr-1 animate-spin" />
            },
            'error': {
                color: 'bg-red-100 text-red-800',
                icon: <AlertTriangle className="w-4 h-4 mr-1" />
            }
        };

        const config = statusConfig[template.status] || statusConfig.error;

        return (
            <div className={`flex items-center px-2 py-1 rounded-full text-xs font-medium ${config.color}`}>
                {config.icon}
                {t(`dashboard.templates.status.${template.status}`)}
            </div>
        );
    };

    return (
        <Card className="h-full flex flex-col border border-gray-200 hover:shadow-md transition-shadow rounded-xl overflow-hidden">
            {/* Card header */}
            <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-4 border-b border-gray-200">
                <div className="flex justify-between items-start">
                    <div>
                        <Typography variant="h6" className="text-blue-700 font-semibold">
                            {template.name}
                        </Typography>
                    </div>
                    <IconButton
                        size="small"
                        onClick={handleOpenMenu}
                        aria-label={t('dashboard.templates.actions')}
                    >
                        <MoreVertical className="w-5 h-5" />
                    </IconButton>
                </div>
            </div>

            {/* Card content */}
            <CardContent className="flex-grow flex flex-col p-4">
                <div className="flex flex-wrap gap-2 mb-3">
                    {renderStatusBadge()}

                    <Tooltip title={t('dashboard.templates.components_count')}>
                        <div className="flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            <Server className="w-4 h-4 mr-1" />
                            {template.components_count || 0}
                        </div>
                    </Tooltip>
                </div>

                {/* Metadata section */}
                <div className="mt-auto text-sm text-gray-600">
                    {template.created_at && (
                        <div className="flex items-center mt-2">
                            <Calendar className="w-4 h-4 mr-2" />
                            <span>{t('dashboard.templates.created')}: {new Date(template.created_at).toLocaleDateString()}</span>
                        </div>
                    )}

                    {template.created_by && (
                        <div className="flex items-center mt-2">
                            <Avatar
                                sx={{ width: 20, height: 20, fontSize: '0.75rem', marginRight: '8px' }}
                                alt={template.created_by.username}
                            >
                                {template.created_by.username.charAt(0).toUpperCase()}
                            </Avatar>
                            <span>{t('dashboard.templates.author')}: {template.created_by.username}</span>
                        </div>
                    )}

                    {template.sandbox_name && (
                        <div className="flex items-center mt-2">
                            <Server className="w-4 h-4 mr-2" />
                            <span>{t('dashboard.templates.based_on')}: {template.sandbox_name}</span>
                        </div>
                    )}
                </div>
            </CardContent>

            {/* Card actions */}
            <CardActions className="border-t border-gray-200 p-2 bg-gray-50">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Play className="w-4 h-4" />}
                    onClick={handleOpenDeployConfirm}
                    fullWidth
                >
                    {t('dashboard.templates.deploy')}
                </Button>
            </CardActions>

            {/* Context Menu */}
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={handleOpenDeployConfirm}>
                    <Play className="w-4 h-4 mr-2" />
                    {t('dashboard.templates.deploy')}
                </MenuItem>

                {isOwner && (
                    <>
                        <MenuItem onClick={handleOpenShareDialog}>
                            <Share2 className="w-4 h-4 mr-2" />
                            {t('dashboard.templates.share')}
                        </MenuItem>

                        <MenuItem onClick={handleOpenDeleteConfirm} className="text-red-600">
                            <Trash2 className="w-4 h-4 mr-2" />
                            {t('dashboard.templates.delete')}
                        </MenuItem>
                    </>
                )}
            </Menu>

            {/* Deploy Confirmation Dialog */}
            <Dialog
                open={deployConfirmOpen}
                onClose={() => !isDeploying && setDeployConfirmOpen(false)}
            >
                <DialogTitle>
                    {t('dashboard.templates.deploy_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('dashboard.templates.deploy_confirm', { name: template.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeployConfirmOpen(false)}
                        disabled={isDeploying}
                    >
                        {t('dashboard.templates.cancel')}
                    </Button>
                    <Button
                        onClick={handleDeployTemplate}
                        variant="contained"
                        color="primary"
                        disabled={isDeploying}
                        startIcon={isDeploying ? <CircularProgress size={20} /> : <Play size={16} />}
                    >
                        {isDeploying
                            ? t('dashboard.templates.deploying')
                            : t('dashboard.templates.deploy')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteConfirmOpen}
                onClose={() => !isDeleting && setDeleteConfirmOpen(false)}
            >
                <DialogTitle className="text-red-600">
                    {t('dashboard.templates.delete_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('dashboard.templates.delete_confirm', { name: template.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteConfirmOpen(false)}
                        disabled={isDeleting}
                    >
                        {t('dashboard.templates.cancel')}
                    </Button>
                    <Button
                        onClick={handleDeleteTemplate}
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        startIcon={isDeleting ? <CircularProgress size={20} /> : <Trash2 size={16} />}
                    >
                        {isDeleting
                            ? t('dashboard.templates.deleting')
                            : t('dashboard.templates.delete')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Share Template Dialog */}
            {shareDialogOpen && (
                <ShareTemplateDialog
                    open={shareDialogOpen}
                    onClose={() => setShareDialogOpen(false)}
                    template={template}
                    token={token}
                    setError={setError}
                    onSuccess={() => setShareDialogOpen(false)}
                />
            )}
        </Card>
    );
};

export default TemplateCard;