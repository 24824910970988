// src/components/dashboard/tabs/ShareSandboxDialog.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    CircularProgress,
    Autocomplete,
    Chip
} from '@mui/material';
import { Search, UserPlus, X } from 'lucide-react';
import { searchUsers, updateSandboxCoworkers } from '../../../services/apiService';

const ShareSandboxDialog = ({ open, onClose, sandbox, token, setError, onSuccess }) => {
    const { t } = useTranslation();

    // Состояния
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [currentCoworkers, setCurrentCoworkers] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Загружаем текущих соавторов при открытии диалога
    useEffect(() => {
        if (open && sandbox) {
            // Предполагаем, что в объекте sandbox есть свойство coworkers
            setCurrentCoworkers(sandbox.coworkers || []);
        }
    }, [open, sandbox]);

    // Поиск пользователей
    const handleSearch = async () => {
        if (!searchTerm.trim()) return;

        setIsSearching(true);
        try {
            const results = await searchUsers(token, searchTerm);
            setSearchResults(results);
        } catch (err) {
            setError(t('dashboard.sandboxes.user_search_error'));
            console.error('Error searching users:', err);
        } finally {
            setIsSearching(false);
        }
    };

    // Добавление пользователя в список выбранных
    const handleSelectUser = (user) => {
        if (!selectedUsers.find(u => u.id === user.id)) {
            setSelectedUsers([...selectedUsers, user]);
        }
        setSearchTerm('');
        setSearchResults([]);
    };

    // Удаление пользователя из списка выбранных
    const handleRemoveUser = (userId) => {
        setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
    };

    // Сохранение изменений
    const handleSave = async () => {
        setIsSaving(true);

        try {
            // Собираем ID текущих соавторов и новых выбранных пользователей
            const coworkerIds = [
                ...currentCoworkers.map(user => user.id),
                ...selectedUsers.map(user => user.id)
            ];

            await updateSandboxCoworkers(token, sandbox.id, coworkerIds);

            if (onSuccess) {
                onSuccess();
            }

            onClose();
        } catch (err) {
            setError(t('dashboard.sandboxes.share_error'));
            console.error('Error updating coworkers:', err);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {t('dashboard.sandboxes.share_title')}
            </DialogTitle>

            <DialogContent>
                <Typography className="mb-4">
                    {t('dashboard.sandboxes.share_description', { name: sandbox?.name })}
                </Typography>

                {/* Поиск пользователей */}
                <div className="mb-4">
                    <Autocomplete
                        freeSolo
                        options={searchResults}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.username
                        }
                        renderOption={(props, option) => (
                            <li {...props} key={option.id} onClick={() => handleSelectUser(option)}>
                                {option.username}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('dashboard.sandboxes.search_users')}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isSearching ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <IconButton size="small" onClick={handleSearch}>
                                                    <Search className="w-5 h-5" />
                                                </IconButton>
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}></TextField>
                        )}
                    />
                </div>

                {/* Выбранные пользователи */}
                {selectedUsers.length > 0 && (
                    <div className="mb-4">
                        <Typography variant="subtitle2" className="mb-2">
                            {t('dashboard.sandboxes.selected_users')}
                        </Typography>
                        <div className="flex flex-wrap gap-2">
                            {selectedUsers.map(user => (
                                <Chip
                                    key={user.id}
                                    label={user.username}
                                    onDelete={() => handleRemoveUser(user.id)}
                                    color="primary"
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Текущие соавторы */}
                {currentCoworkers.length > 0 && (
                    <div>
                        <Typography variant="subtitle2" className="mb-2">
                            {t('dashboard.sandboxes.current_coworkers')}
                        </Typography>
                        <List>
                            {currentCoworkers.map(user => (
                                <ListItem key={user.id} divider>
                                    <ListItemText primary={user.username} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" onClick={() => {
                                            // Удаление соавтора потребует дополнительной логики
                                            // Для простоты оставим это пока без реализации
                                        }}>
                                            <X className="w-4 h-4" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    {t('dashboard.sandboxes.cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={selectedUsers.length === 0 || isSaving}
                    startIcon={isSaving ? <CircularProgress size={20} /> : <UserPlus size={16} />}
                >
                    {t('dashboard.sandboxes.share')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShareSandboxDialog;