// src/components/dashboard/tabs/ShareTemplateDialog.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    CircularProgress,
    Autocomplete,
    Chip,
    Tabs,
    Tab
} from '@mui/material';
import { Search, UserPlus, X, Users } from 'lucide-react';
import {
    searchUsers,
    searchGroups,
    getTemplateAccessList,
    addTemplateAccess,
    removeTemplateAccess
} from '../../../services/apiService';

const ShareTemplateDialog = ({ open, onClose, template, token, setError, onSuccess }) => {
    const { t } = useTranslation();

    // State variables
    const [tabValue, setTabValue] = useState(0); // 0 for users, 1 for groups
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [accessList, setAccessList] = useState({ users: [], groups: [] });
    const [isSearching, setIsSearching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Load current access list when dialog opens
    useEffect(() => {
        if (open && template) {
            loadAccessList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, template]);

    // Load template access list
    const loadAccessList = async () => {
        setIsLoading(true);
        try {
            const accessData = await getTemplateAccessList(token, template.id);

            // Separate users and groups
            const users = accessData.filter(item => item.user !== null);
            const groups = accessData.filter(item => item.group !== null);

            setAccessList({ users, groups });
        } catch (err) {
            setError(t('dashboard.templates.error.loading_access'));
            console.error('Error loading template access list:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Search for users or groups
    const handleSearch = async () => {
        if (!searchTerm.trim()) return;

        setIsSearching(true);
        try {
            if (tabValue === 0) {
                // Search for users
                const results = await searchUsers(token, searchTerm);
                setSearchResults(results);
            } else {
                // Search for groups
                const results = await searchGroups(token, searchTerm);
                setSearchResults(results);
            }
        } catch (err) {
            setError(tabValue === 0
                ? t('dashboard.templates.error.user_search_error')
                : t('dashboard.templates.error.group_search_error')
            );
            console.error('Error searching:', err);
        } finally {
            setIsSearching(false);
        }
    };

    // Handle tab change (users/groups)
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setSearchTerm('');
        setSearchResults([]);
    };

    // Add user or group to selection
    const handleSelectItem = (item) => {
        if (tabValue === 0) {
            // Add user if not already selected
            if (!selectedUsers.find(u => u.id === item.id)) {
                setSelectedUsers([...selectedUsers, item]);
            }
        } else {
            // Add group if not already selected
            if (!selectedGroups.find(g => g.id === item.id)) {
                setSelectedGroups([...selectedGroups, item]);
            }
        }
        setSearchTerm('');
        setSearchResults([]);
    };

    // Remove user from selection
    const handleRemoveUser = (userId) => {
        setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
    };

    // Remove group from selection
    const handleRemoveGroup = (groupId) => {
        setSelectedGroups(selectedGroups.filter(group => group.id !== groupId));
    };

    // Remove user or group access
    const handleRemoveAccess = async (accessId) => {
        try {
            await removeTemplateAccess(token, template.id, accessId);
            await loadAccessList(); // Refresh the access list
        } catch (err) {
            setError(t('dashboard.templates.error.removing_access'));
            console.error('Error removing access:', err);
        }
    };

    // Save changes (add new users/groups)
    const handleSave = async () => {
        if (selectedUsers.length === 0 && selectedGroups.length === 0) {
            return; // Nothing to save
        }

        setIsSaving(true);
        try {
            // Add selected users and groups
            await addTemplateAccess(
                token,
                template.id,
                selectedUsers.map(user => user.id),
                selectedGroups.map(group => group.id)
            );

            // Refresh access list
            await loadAccessList();

            // Clear selections
            setSelectedUsers([]);
            setSelectedGroups([]);

            if (onSuccess) {
                onSuccess();
            }
        } catch (err) {
            setError(t('dashboard.templates.error.sharing'));
            console.error('Error sharing template:', err);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {t('dashboard.templates.share_title')}
            </DialogTitle>

            <DialogContent>
                <Typography className="mb-4">
                    {t('dashboard.templates.share_description', { name: template?.name })}
                </Typography>

                {/* User/Group Tabs */}
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="share options"
                    className="mb-4"
                >
                    <Tab
                        icon={<UserPlus className="w-4 h-4 mr-2" />}
                        label={t('dashboard.templates.share_with_users')}
                        iconPosition="start"
                    />
                    <Tab
                        icon={<Users className="w-4 h-4 mr-2" />}
                        label={t('dashboard.templates.share_with_groups')}
                        iconPosition="start"
                    />
                </Tabs>

                {/* Search field */}
                <div className="mb-4">
                    <Autocomplete
                        freeSolo
                        options={searchResults}
                        getOptionLabel={(option) =>
                            typeof option === 'string'
                                ? option
                                : tabValue === 0
                                    ? option.username
                                    : option.name
                        }
                        renderOption={(props, option) => (
                            <li {...props} key={option.id} onClick={() => handleSelectItem(option)}>
                                {tabValue === 0 ? option.username : option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={tabValue === 0
                                    ? t('dashboard.templates.search_users')
                                    : t('dashboard.templates.search_groups')
                                }
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isSearching ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <IconButton size="small" onClick={handleSearch}>
                                                    <Search className="w-5 h-5" />
                                                </IconButton>
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>

                {/* Selected users */}
                {tabValue === 0 && selectedUsers.length > 0 && (
                    <div className="mb-4">
                        <Typography variant="subtitle2" className="mb-2">
                            {t('dashboard.templates.selected_users')}
                        </Typography>
                        <div className="flex flex-wrap gap-2">
                            {selectedUsers.map(user => (
                                <Chip
                                    key={user.id}
                                    label={user.username}
                                    onDelete={() => handleRemoveUser(user.id)}
                                    color="primary"
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Selected groups */}
                {tabValue === 1 && selectedGroups.length > 0 && (
                    <div className="mb-4">
                        <Typography variant="subtitle2" className="mb-2">
                            {t('dashboard.templates.selected_groups')}
                        </Typography>
                        <div className="flex flex-wrap gap-2">
                            {selectedGroups.map(group => (
                                <Chip
                                    key={group.id}
                                    label={group.name}
                                    onDelete={() => handleRemoveGroup(group.id)}
                                    color="primary"
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Current access lists */}
                {isLoading ? (
                    <div className="text-center py-4">
                        <CircularProgress size={24} />
                        <Typography variant="body2" className="mt-2">
                            {t('dashboard.templates.loading_access')}
                        </Typography>
                    </div>
                ) : (
                    <>
                        {/* Users with access */}
                        {tabValue === 0 && accessList.users.length > 0 && (
                            <div>
                                <Typography variant="subtitle2" className="mb-2">
                                    {t('dashboard.templates.users_with_access')}
                                </Typography>
                                <List>
                                    {accessList.users.map(access => (
                                        <ListItem key={access.id} divider>
                                            <ListItemText
                                                primary={access.user_info.username}
                                                secondary={access.user_info.email}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => handleRemoveAccess(access.id)}
                                                >
                                                    <X className="w-4 h-4" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        )}

                        {/* Groups with access */}
                        {tabValue === 1 && accessList.groups.length > 0 && (
                            <div>
                                <Typography variant="subtitle2" className="mb-2">
                                    {t('dashboard.templates.groups_with_access')}
                                </Typography>
                                <List>
                                    {accessList.groups.map(access => (
                                        <ListItem key={access.id} divider>
                                            <ListItemText
                                                primary={access.group_info.name}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => handleRemoveAccess(access.id)}
                                                >
                                                    <X className="w-4 h-4" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        )}
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    {t('dashboard.templates.cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={(selectedUsers.length === 0 && selectedGroups.length === 0) || isSaving}
                    startIcon={isSaving ? <CircularProgress size={20} /> : <UserPlus size={16} />}
                >
                    {t('dashboard.templates.share')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShareTemplateDialog;