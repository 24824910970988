// src/components/dashboard/tabs/DashboardSandboxesTab.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Typography,
    TextField,
    InputAdornment,
    Tabs,
    Tab,
    CircularProgress
} from '@mui/material';
import {
    Rocket,
    Search,
    Plus,
    BookTemplate
} from 'lucide-react';
import {
    fetchActiveSandboxes,
    fetchEnvironmentTemplates,
    startCamundaProcess
} from '../../../services/apiService';
import CreateSandboxDialog from '../../sandbox/CreateSandboxDialog';
import SandboxCard from './SandboxCard';
import TemplateCard from './TemplateCard';
import ShareSandboxDialog from './ShareSandboxDialog';

const DashboardSandboxesTab = ({ userInfo, token, setError }) => {
    const { t } = useTranslation();

    // Состояния для данных
    const [mySandboxes, setMySandboxes] = useState([]);
    const [accessibleSandboxes, setAccessibleSandboxes] = useState([]);
    const [myTemplates, setMyTemplates] = useState([]);
    const [accessibleTemplates, setAccessibleTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Состояния для поиска
    const [sandboxSearch, setSandboxSearch] = useState('');
    const [templateSearch, setTemplateSearch] = useState('');

    // Состояния для диалогов
    const [createSandboxDialogOpen, setCreateSandboxDialogOpen] = useState(false);
    const [shareSandboxDialogOpen, setShareSandboxDialogOpen] = useState(false);
    const [selectedSandbox, setSelectedSandbox] = useState(null);

    // Состояние для подразделов
    const [activeTab, setActiveTab] = useState(0);

    // Загрузка данных
    useEffect(() => {
        const loadSandboxesData = async () => {
            setIsLoading(true);

            try {
                // Загрузка песочниц
                const [mySandboxesData, accessibleSandboxesData] = await Promise.all([
                    fetchActiveSandboxes(token, userInfo.id, 'owner'),
                    fetchActiveSandboxes(token, userInfo.id, 'coworker')
                ]);

                setMySandboxes(mySandboxesData);
                setAccessibleSandboxes(accessibleSandboxesData);

                // Загрузка шаблонов
                const templatesData = await fetchEnvironmentTemplates(token);

                // Разделяем на свои и доступные шаблоны на основе created_by
                const ownTemplates = [];
                const accessibleTemplates = [];

                templatesData.forEach(template => {
                    // В EnvironmentTemplateSerializer есть метод get_created_by, который
                    // возвращает информацию о создателе
                    if (template.created_by && template.created_by.id === userInfo.id) {
                        ownTemplates.push(template);
                    } else {
                        accessibleTemplates.push(template);
                    }
                });

                setMyTemplates(ownTemplates);
                setAccessibleTemplates(accessibleTemplates);

            } catch (err) {
                setError(t('dashboard.sandboxes.error.loading_data'));
                console.error('Error loading sandboxes data:', err);
            } finally {
                setIsLoading(false);
            }
        };

        loadSandboxesData();
    }, [token, userInfo, setError, t]);

    // Обработчики для песочниц
    const handleOpenShareSandbox = (sandbox) => {
        setSelectedSandbox(sandbox);
        setShareSandboxDialogOpen(true);
    };

    // Обработчик создания песочницы
    const handleCreateSandbox = async () => {
        // Используем существующий компонент CreateSandboxDialog и его логику
        setCreateSandboxDialogOpen(false);

        // После успешного создания песочницы, обновляем список
        const refreshSandboxes = async () => {
            try {
                const newMySandboxes = await fetchActiveSandboxes(token, userInfo.id, 'owner');
                setMySandboxes(newMySandboxes);
            } catch (err) {
                console.error('Error refreshing sandboxes:', err);
            }
        };

        // Обновляем списки после создания новой песочницы с небольшой задержкой
        setTimeout(refreshSandboxes, 2000);
    };

    // Обработчик удаления песочницы
    const handleDeleteSandbox = async (sandboxId) => {
        try {
            await startCamundaProcess(
                token,
                'delete',
                userInfo.id,
                'delete',
                sandboxId
            );

            // Обновляем список песочниц
            setMySandboxes(mySandboxes.filter(s => s.id !== sandboxId));

        } catch (err) {
            setError(t('dashboard.sandboxes.error.deleting'));
            console.error('Error deleting sandbox:', err);
        }
    };

    // Фильтрация песочниц/шаблонов по поиску
    const filteredMySandboxes = mySandboxes.filter(sandbox =>
        sandbox.name.toLowerCase().includes(sandboxSearch.toLowerCase())
    );

    const filteredAccessibleSandboxes = accessibleSandboxes.filter(sandbox =>
        sandbox.name.toLowerCase().includes(sandboxSearch.toLowerCase())
    );

    const filteredMyTemplates = myTemplates.filter(template =>
        template.name.toLowerCase().includes(templateSearch.toLowerCase())
    );

    const filteredAccessibleTemplates = accessibleTemplates.filter(template =>
        template.name.toLowerCase().includes(templateSearch.toLowerCase())
    );

    // Переключение вкладок
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-20">
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="space-y-8">
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                className="bg-white rounded-lg shadow-sm px-6 py-2"
            >
                <Tab
                    icon={<Rocket className="w-5 h-5" />}
                    label={t('dashboard.sandboxes.tabs.sandboxes')}
                    iconPosition="start"
                />
                <Tab
                    icon={<BookTemplate className="w-5 h-5" />}
                    label={t('dashboard.sandboxes.tabs.templates')}
                    iconPosition="start"
                />
            </Tabs>

            {/* Контент вкладки Песочницы */}
            {activeTab === 0 && (
                <div className="space-y-8">
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl font-semibold">{t('dashboard.sandboxes.my_sandboxes')}</h2>
                        <Button
                            variant="contained"
                            startIcon={<Plus className="w-4 h-4" />}
                            onClick={() => setCreateSandboxDialogOpen(true)}
                        >
                            {t('dashboard.sandboxes.create_sandbox')}
                        </Button>
                    </div>

                    <TextField
                        fullWidth
                        placeholder={t('dashboard.sandboxes.search_sandboxes')}
                        value={sandboxSearch}
                        onChange={(e) => setSandboxSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search className="w-5 h-5 text-gray-400" />
                                </InputAdornment>
                            ),
                        }}
                        className="bg-white rounded-lg mb-4"
                    />

                    {/* Мои песочницы */}
                    <div className="bg-white rounded-xl shadow-sm p-6">
                        <Typography variant="h6" className="mb-4">
                            {t('dashboard.sandboxes.owned_by_me')}
                        </Typography>

                        {filteredMySandboxes.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {filteredMySandboxes.map(sandbox => (
                                    <SandboxCard
                                        key={sandbox.id}
                                        sandbox={sandbox}
                                        isOwner={true}
                                        onShare={() => handleOpenShareSandbox(sandbox)}
                                        onDelete={() => handleDeleteSandbox(sandbox.id)}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="text-center py-6 text-gray-500">
                                {t('dashboard.sandboxes.no_sandboxes')}
                            </div>
                        )}
                    </div>

                    {/* Доступные песочницы */}
                    {filteredAccessibleSandboxes.length > 0 && (
                        <div className="bg-white rounded-xl shadow-sm p-6">
                            <Typography variant="h6" className="mb-4">
                                {t('dashboard.sandboxes.shared_with_me')}
                            </Typography>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {filteredAccessibleSandboxes.map(sandbox => (
                                    <SandboxCard
                                        key={sandbox.id}
                                        sandbox={sandbox}
                                        isOwner={false}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* Контент вкладки Шаблоны */}
            {activeTab === 1 && (
                <div className="space-y-8">
                    <TextField
                        fullWidth
                        placeholder={t('dashboard.templates.search_templates')}
                        value={templateSearch}
                        onChange={(e) => setTemplateSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search className="w-5 h-5 text-gray-400" />
                                </InputAdornment>
                            ),
                        }}
                        className="bg-white rounded-lg mb-4"
                    />

                    {/* Мои шаблоны */}
                    <div className="bg-white rounded-xl shadow-sm p-6">
                        <Typography variant="h6" className="mb-4">
                            {t('dashboard.templates.my_templates')}
                        </Typography>

                        {filteredMyTemplates.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {filteredMyTemplates.map(template => (
                                    <TemplateCard
                                        key={template.id}
                                        template={template}
                                        token={token}
                                        refreshTemplates={() => {
                                            // Будем обновлять список шаблонов после действий
                                        }}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="text-center py-6 text-gray-500">
                                {t('dashboard.templates.no_templates')}
                            </div>
                        )}
                    </div>

                    {/* Доступные шаблоны */}
                    {filteredAccessibleTemplates.length > 0 && (
                        <div className="bg-white rounded-xl shadow-sm p-6">
                            <Typography variant="h6" className="mb-4">
                                {t('dashboard.templates.shared_templates')}
                            </Typography>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {filteredAccessibleTemplates.map(template => (
                                    <TemplateCard
                                        key={template.id}
                                        template={template}
                                        token={token}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* Диалоги */}
            <CreateSandboxDialog
                open={createSandboxDialogOpen}
                onClose={() => setCreateSandboxDialogOpen(false)}
                onSubmit={handleCreateSandbox}
                token={token}
                error={null}
                setError={setError}
                componentTypes={[]}
            />

            {selectedSandbox && (
                <ShareSandboxDialog
                    open={shareSandboxDialogOpen}
                    onClose={() => setShareSandboxDialogOpen(false)}
                    sandbox={selectedSandbox}
                    token={token}
                    setError={setError}
                    onSuccess={() => {
                        // После успешного обновления соавторов
                        setShareSandboxDialogOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default DashboardSandboxesTab;